/*
 *   File : acccordion.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : FAQ Accordion style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_faq_accordion {
	.e_accordion_sec {
		box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.48);
		border-radius: 8px;
		width: 100%;
		padding: 0px 10px 14px;
		margin-bottom: 20px;
	}
	.e_add_sec {
		box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.48);
		border-radius: 8px;
		width: 100%;
		padding: 7px 10px 14px;
		margin-bottom: 20px;

		h6 {
			color: $primary_color !important;
			@extend %font14;
			line-height: 24px;
			font-weight: 700 !important;
			padding: 12px 10px 0px;
			width: 100%;
			cursor: pointer;
			&:hover {
				translate: scale(1.5);
			}
		}
	}
	.e_accordion_button {
		border: none !important;
		background-color: $white;
		text-align: left;
		width: 100%;
		position: relative;
		border-radius: 0;
		outline: none;
		// &[aria-expanded="false"] {
			.e_accordion_icon {
				position: absolute;
				top: 35%;
				right: 0px;
				background-image: url(../../assets/images/dataFormating/add.svg);
				background-repeat: no-repeat;
				padding: 13px;
				transition: 0.5s ease-in-out;
			}
		// }
		// &[aria-expanded="true"] {
			.e_accordion_icon_minus {
				position: absolute;
				top: 35%;
				right: 0px;
				background-image: url(../../assets/images/dataFormating/minus.svg);
				background-repeat: no-repeat;
				padding: 13px;
				transition: 0.5s ease-in-out;
			}
		// }
	}
	.e_accordion_item {
		border: none !important;
		.e_accordion_header {
			background: $white;
		}
	}
	.e_accordion_title {
		font-weight: 500;
		@extend %font14;
		line-height: 24px;
		color: $dark_grey;
		&:hover {
			cursor: pointer;
			text-decoration: underline;
		}
	}
	.e_accordion_sub_title {
		font-weight: 700;
		@extend %font14;
		line-height: 20px;
		padding: 0px 16px;
		color: $dark_grey;
		&:hover {
			cursor: pointer;
			text-decoration: none;
		}
	}
	.e_accordion_wrapper {
		background: $accordion_bg;
		border-radius: 8px;
		padding: 10px;
		margin: 2px;
		.e_accordion_content_img {
			width: 4%;
		}
		.e_accordion_content {
			font-weight: 400;
			@extend %font14;
			line-height: 150%;
			color: $dark_grey;
			h6 {
				@extend %font14;
				font-weight: 600;
				padding-top: 15px;
			}
		}
	}
	.e_change {
		height: 40px;
		text-align: center;
		border: 1px solid $primary_color;
		padding: 8px 12px;
		border-radius: 8px;
		font-weight: 700;
		&:hover {
			opacity: 0.7;
		}
	}
	.e_delete {
		height: 40px;
		text-align: center;
		border: 1px solid $badge_fail;
		padding: 8px 12px;
		border-radius: 8px;
		font-weight: 700;
		color: $badge_fail;
		font-weight: 700;
		&:hover {
			opacity: 0.7;
		}
	}
	.e_edit {
		height: 40px;
		text-align: center;
		border: 1px solid $primary_color;
		padding: 8px 12px;
		border-radius: 8px;
		font-weight: 700;

		&:hover {
			opacity: 0.7;
		}
	}
}
.e_accordion_body {
	padding: 0 0 !important;
}

.e_quiz_accordion {
	.e_accordion_sec {
		box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.48);
		border-radius: 8px;
		width: 100%;
		padding: 4px 10px 14px;
		margin-bottom: 20px;
	}
	.e_add_sec {
		box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.48);
		border-radius: 8px;
		width: 100%;
		padding: 7px 10px 14px;
		margin-bottom: 20px;

		h6 {
			color: $primary_color !important;
			@extend %font14;
			line-height: 24px;
			font-weight: 700 !important;
			padding: 12px 10px 0px;
			width: 100%;
			cursor: pointer;
			&:hover {
				translate: scale(1.5);
			}
		}
	}
	.e_accordion_button {
		border: none !important;
		background-color: $white;
		text-align: left;
		width: 100%;
		position: relative;
		border-radius: 0;
		outline: none;
		// &[aria-expanded="false"] {
			.e_accordion_icon {
				position: absolute;
				top: 32%;
				right: 0px;
				background-image: url(../../assets/images/dataFormating/add.svg);
				background-repeat: no-repeat;
				padding: 13px;
				transition: 0.5s ease-in-out;
			}
		// }
		// &[aria-expanded="true"] {
			.e_accordion_icon_minus {
				position: absolute;
				top: 35%;
				right: 0px;
				background-image: url(../../assets/images/dataFormating/minus.svg);
				background-repeat: no-repeat;
				padding: 13px;
				transition: 0.5s ease-in-out;
			}
		// }
	}
	.e_accordion_item {
		border: none !important;
		.e_accordion_header {
			background: $white;
		}
	}
	.e_accordion_title {
		font-weight: 500;
		@extend %font14;
		line-height: 20px;
		color: $dark_grey;
		&:hover {
			cursor: pointer;
			text-decoration: underline;
		}
	}
	.e_accordion_sub_title {
		font-weight: 700;
		@extend %font14;
		line-height: 20px;
		padding: 0px 16px;
		color: $dark_grey;
		&:hover {
			cursor: pointer;
			text-decoration: none;
		}
	}
	.e_accordion_wrapper {
		background: $accordion_bg;
		border-radius: 8px;
		padding: 14px 6px;
		margin: 2px;
		display: inherit;
		h6 {
			@extend %font12;
			font-weight: 700 !important;
		}
		li {
			@extend %font14;
			line-height: 34px;
			color: $dark_grey;
			opacity: 0.87;
		}
		.e_accordion_content_img {
			width: 4%;
		}
		.e_accordion_content {
			font-weight: 400;
			@extend %font14;
			line-height: 150%;
			color: $dark_grey;
			li::marker {
				font-size: 8px;
				font-weight: 300;
				color: $dark_grey;
			}
		}
	}
	.e_delete {
		height: 40px;
		text-align: center;
		border: 1px solid $badge_fail;
		padding: 8px 12px;
		border-radius: 8px;
		font-weight: 700;
		color: $badge_fail;
		font-weight: 700;
		&:hover {
			opacity: 0.7;
		}
	}
	.e_edit {
		height: 40px;
		text-align: center;
		border: 1px solid $primary_color;
		padding: 8px 12px;
		border-radius: 8px;
		font-weight: 700;

		&:hover {
			opacity: 0.7;
		}
	}
}
.e_accordion_body {
	padding: 0 0 !important;
}
