
@import './../config.scss';
.e_nodata_wrapper{
    width: 100%;
    img{
        vertical-align: middle;
        width: 300px;
    }
    h5{
        color: #010101;
        text-align: center;
        letter-spacing: -0.02em;
        font-weight: bold;
        font-size: 18px;    
    }
    p{
        color: #6D6D6D;
        text-align: center;
        letter-spacing: -0.02em;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
    }
}