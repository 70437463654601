/*
 *   File : addFaq.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : FAQ Accordion style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";
.e_add_fund {
  li::marker {
    font-size: 0px;
    list-style: none;
  }

  li {
    min-height: 100px;
  }
  .e_topic_icon:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
  .e_drag {
    // height: 30px;
    margin-top: -20px;
    padding-bottom: 20px;
  }
  .e_drag_icon{
   position: absolute;
   top: 50%;
  }
  .e_input_box {
    background: #ffffff;
    border: 0.5px solid #d2d2d2;
    border-radius: 8px;
    .e_input {
      border: none !important;
      width: 85%;
    }
   
  }
  .e_desc_label{
    font-weight: 600;
    font-size: 14px;
  }
  h6 {
    @extend %h6;
    font-weight: 700 !important;
    line-height: 130% !important;
  }
  p {
    font-size: 12px;
    color: $dark_grey;
    font-weight: 400;
    line-height: 18px;
  }
  .css-1s2u09g-control {
    min-height: 50px !important;
  }
  .e_cancel {
    border: solid 1px $light_grey !important;
    color: $light_grey;
  }
  .e_add_sec {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.48);
    border-radius: 8px;
    width: 100%;
    margin-bottom: 20px;
    h6 {
      color: $primary_color !important;
      @extend %font14;
      line-height: 24px;
      font-weight: 700 !important;
      width: 100%;
      cursor: pointer;
      &:hover {
        translate: scale(1.5);
      }
    }
   
  }
  .e_add_new_answer{
    color: $primary_color !important;
    @extend %font14;
    line-height: 24px;
    font-weight: 700 !important;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
      &:hover {
        translate: scale(1.5);
      }
  }
  .e_choice_sec {
    background: $body_bg;
    border: 0.5px solid $accordion_body_bg;
    box-sizing: border-box;
    width: 100%;
    padding: 14px;
    margin-bottom: 20px;
    border-radius: 8px;
    .e_choices {
      color: $dark_grey !important;
      @extend %font14;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      cursor: pointer;
      &:hover {
        translate: scale(1.5);
      }
    }
  }

  .e_search_submit {
    width: 45px;
    height: 46.5px;
    display: inline-block;
    position: absolute;
    right: 12px;
    top: 27px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background-image: url(../../assets/images/cta/enterarrow.svg);
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-color: $primary_color;
    cursor: pointer;
    transition: ease-in-out 0.3s;
    // left: 83%;
    // top: -46%;
  }
  .e_search_close {
    background-image: url(../../assets/images/cta/close-black.png);
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;
    background-size: contain;
    border: none !important;
    outline: none !important;
    background-color: transparent;
    top: 44px;
    opacity: 0.5;
    // left: 75%;
    right: 65px;
    position: absolute;
    &:hover {
      opacity: 1;
    }
  }

  .e_add_quiz_sec {
    border: solid 0.5px $light_grey;
    border-radius: 8px;
    width: 98%;
    margin-bottom: 20px;
    padding: 15px 8px 0px;
    h5 {
      color: $dark_grey !important;
      @extend %font14;
      line-height: 18px;
      font-weight: 400 !important;
      width: 100%;
      cursor: pointer;
    }
    h6 {
      color: $primary_color !important;
      @extend %font14;
      line-height: 24px;
      font-weight: 700 !important;
      width: 100%;
      cursor: pointer;
      &:hover {
        translate: scale(1.5);
      }
    }
    span {
      font-weight: 400;
      @extend %font14;
      line-height: 18px;
      color: $dark_grey;
    }
  }
  .e_add_new_quiz_sec {
    // border: solid 0.5px $light_grey;
    border-radius: 8px;
    width: 98%;
    margin-bottom: 20px;
    padding: 12px 10px 8px;
    h5 {
      color: $dark_grey !important;
      @extend %font14;
      line-height: 18px;
      font-weight: 400 !important;
      width: 100%;
      cursor: pointer;
    }
    h6 {
      color: $primary_color !important;
      @extend %font14;
      line-height: 24px;
      font-weight: 700 !important;
      width: 100%;
      cursor: pointer;
      &:hover {
        translate: scale(1.5);
      }
    }
  }
}
.e_disabled {
  opacity: 0.5;
  pointer-events: none;
  padding: 0 !important;
  width: 129px;
  height: 40px;
  font-weight: 500;
  font-size: 0.875rem;
}

.e_input_box {
  content: "%";
  background-position: 80%;
}

.e_save {
  height: 35px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $primary_color;
  color: $white;
  @extend %font12;
  line-height: 20px;
  border: 1px solid $primary_color;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 10px;
  float: right;
  font-weight: 500;
}
.e_save_disabled {
   height: 35px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.4;
  background: $primary_color;
  color: $white;
  @extend %font12;
  line-height: 20px;
  border: 1px solid #1329e6;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 10px;
  float: right;
  font-weight: 500;
}
