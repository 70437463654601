 /*
 *   File : Textarea.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Textarea style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_textarea{
  @extend %font14;
  background: $white;
  border: 0.5px solid $input_boder;
  outline: none;
  width: 100%;
  font-weight: 400;
  color: $dark_grey;
  padding: 12px 15px;
  border-radius: 8px;
  resize: none;
  // height: 262px;
  // min-height: 190px;
  &::placeholder{
    font-weight: 400;
    line-height: 18px;
    color: $dark_grey;
    opacity: 0.27;
  }
}

.e_textarea:focus {
  border: 0.5px solid $dark_grey;
}
.e_input_error{
  border: 1px solid $error;

}
.e_error_msg{
  @extend %font12;
  color: $error!important;
}