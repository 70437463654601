
@import '../config.scss';

.e_history {
    .e_modal_dialog {
        .e_card_container {
            height: 400px;
            overflow: auto;
        
              &::-webkit-scrollbar {
                // border-radius: 10px;
                height: 0px !important;
                background: #F0F0F0;
                width: 4px;
            }
        
            &::-webkit-scrollbar-thumb {
                background: #C0C0C0;
                border-radius: 12px;
            }
        }
              
            .e_history_card {
                background: #e0e3e7ab;
                border-radius: 8px;
            }
            .e_placeholder {
                background-color: rgb(114, 122, 0);
                padding: 12px 17.5px;
                border-radius: 100px;
                color: #ffffff;
                font-size: 20px;
                font-weight: 500;
            }
            .e_name {
                font-weight: 600;
            }
    }

}