/*
 *   File : outerHeader.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Header
 *   Integrations : null
 *   Version : 1.0
 *   Created : 10-11-2021
 */

@import "./../config.scss";

.e_outer_header{
	padding: 35px 52px;
	background-color: $white;
	.e_logo {		
    width: 130.43px;
    height: 43px;
		&:hover {
			cursor: pointer;
		}
	}
	.e_btn {
		&:hover {
			color: pointer;
			color: $dark_grey;
			text-decoration: underline;
			transition: .3s ease-in-out;
		}
	}
}