/*
 *   File : radio.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : InputBox style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 20-12-2021
 */

@import "./../config.scss";



 @import "./../config.scss";

$md-radio-checked-color: rgb(51, 122, 183);
$md-radio-border-color: $black;
$md-radio-size: 21px;
$md-radio-checked-size: 8px; 
$md-radio-ripple-size: 15x;

.e_radiobutton {
  margin: 16px 0;

  input[type="radio"] {
    display: none;

    &:checked + label:before {
        border-color: $primary_color;
    }

    &:checked + label:after {
        transform: scale(1);
    }
  }

  label {
    display: inline-block;
    height:$md-radio-size;
    position: relative;
    padding: 2px ($md-radio-size + 5px);
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
    @extend %font14;
    font-weight: 400!important;
    color: $dark_grey;
    line-height: 17px;
    
    &:before, &:after {
      position: absolute;
      content: '';
      border-radius: 50%;
      transition: all .3s ease;
      transition-property: transform, border-color;
    }

    &:before {
      left: 0;
      top: 0;
      width: $md-radio-size;
      height: $md-radio-size;
      border: 2px solid $md-radio-border-color;
    }

    &:after {
      top: $md-radio-size / 2 - $md-radio-checked-size / 2;
      left: $md-radio-size / 2 - $md-radio-checked-size / 2;
      width:$md-radio-checked-size;
      height:$md-radio-checked-size;
      transform: scale(0);
      background:$primary_color;
    }
  }
}
.radioButton_e_radiobutton__gL4Sj label:before{
  width: 22px;
  height: 22px;
}
.radioButton_e_radiobutton__gL4Sj label:after{
  display:inline-block;
  width: 10px;
  height: 10px;
}
