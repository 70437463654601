/*
 *   File : primaryLink.Text.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Primary Link Text Button Style
 *   Integrations : null
 *   Version : v1.0
 */

@import "./../config.scss";

.e_primary_link{
	color: $primary_color;
	font-weight: 600;
	@extend %font14;
	background-color: transparent;
	border: none;
	outline: none;
	line-height: 17px;
	position: relative;
  &:hover{
    cursor: pointer;
    opacity: 0.7;
  }
}