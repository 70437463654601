/*
 *   File : splashScreen.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : loader
 *   Integrations :
 *   Version : v1.0
 *   Created : 10-11-2021
 */

@import "./../config.scss";

.e_splash_screen{
  img{
    width: 48px;
    height: 48px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -50px 0px 0px -50px;
  }
}
