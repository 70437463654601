/*
 *   File : secondary.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Secondary Button Style
 *   Integrations :
 *   Version : v1.0
 */

 @import "./../config.scss";

 .e_s_btn{
	background: $white;
	border: 1px solid $secondary_hover_border!important;
	box-sizing: border-box;
	border-radius: 8px;
	outline: none;
	padding: 18px 40px;
	color: $secondary_hover_border;
	font-weight: 700;
	@extend %font14;
	line-height: 120%;
	position: relative;
	z-index: 0;
	&:after{
    color: $secondary_hover_border;
		background: $secondary_btn_hover;
		border-radius: 8px;
    height: 100%!important;
    position: absolute;
    content: "";
    top: 0px;
    left: 0;
    width: 0;
    transition: all .35s;
    z-index: 1;
    opacity: 0;
	}
	&:hover:after{
		opacity: 1;
		width: 100%;
		box-shadow: none!important;
	}
	span {
		z-index: 999;
		position: relative;
	}
}
.e_disabled{
	opacity: 0.5;
	pointer-events: none;
	font-weight: 600;
}
 
 