/*
 *   File : badge.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Badge style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_success {
	border-radius: 20px;
	font-weight: 600;
	@extend %font12;
	line-height: 14px;
	text-align: center;
	background: $status_bg;
	border-radius: 100px;
	padding: 2px 16px 3px;
	color: $white;
	text-transform: capitalize;
	width: 80px;
}
.e_fail {
	border-radius: 20px;
	font-weight: 600;
	@extend %font12;
	line-height: 14px;
	text-align: center;
	background: $badge_fail;
	border-radius: 100px;
	padding: 2px 24px 3px;
	color: $white;
	text-transform: capitalize;
	width: 80px;
}
.e_processing {
	border-radius: 20px;
	font-weight: 600;
	@extend %font12;
	line-height: 14px;
	text-align: center;
	background: $orange;
	border-radius: 100px;
	padding: 2px 16px 3px;
	color: $white;
	text-transform: capitalize;
	width: 80px;
}
.e_tool_tip {
	background-color: #eef2f5;
	position: absolute;
	top: 2.5rem;
	min-width: 200px;
	color: black;
	font-size: 12px;
	right: 5rem;
	padding: 10px 14px;
	border-radius: 4px;
	text-align: left;
	box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.08);
	font-weight: 600;
	z-index: 3;
}
