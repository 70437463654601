/*
 *   File : input.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : InputBox style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 09-12-2021
 */

 @import "./../config.scss";

.e_input_box{
  @extend %font14;
  background: $white;
  border: 0.5px solid $input_boder;
  outline: none;
  width: 100%;
  font-weight: 400;
  color: $dark_grey;
  padding: 12px 15px;
  border-radius: 8px;
  &::placeholder{
    font-weight: 400;
    line-height: 18px;
    color: $dark_grey;
    opacity: 0.27;
  }
}
.e_input_box:focus {
  border: 0.5px solid $dark_grey;
}
.e_input_error{
  border: 1px solid $error;

}
.e_error_msg{
  @extend %font12;
  color: $error !important;
}
label {
  font-weight: 400;
  @extend %font12;
  line-height: 18px;
  color: $dark_grey;
  opacity: 0.87;
  padding-bottom: 4px;
}
.e_suffix{
  position: absolute;
  top: 38px;
  right: 22px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}