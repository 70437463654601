 /*
 *   File : addFaq.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : FAQ Accordion style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";
.e_add_faq {
  h6 {
    @extend %h6;
    line-height: 130%;
  }
  .secondary_e_s_btn__39sgM {
    text-align: center!important;
  }

}
.e_disabled {
  opacity: 0.7;
  pointer-events: none;
  font-weight: 600;
}