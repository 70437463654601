/*
 *   File : profileKyc.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : profileKycl.module.scss
 *   Integrations :
 *   Version : v1.0
 */


@import "./../config.scss";

.e_profileKyc_card{
  background-color: $white;
  box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.04);
  border-radius: 8px!important;
  padding: 24px 35px 24px 35px!important;
  transition: box-shadow .3s;
  z-index: 0;
  border: none!important;
  outline: none;
  margin-bottom: 25px;
  // z-index: -2;
  h5 {
    @extend %heading;
    @extend %font14;
  }
  .e_verified {
    margin-left: 22px;
    font-weight: 400;
    @extend %font12;
    line-height: 15px;
  }
  .e_edit_icon {
    right: 30px;

    &:hover {
      cursor: pointer;
    }
  }
  .e_content_wrap {
    background: $body_bg;
    border-radius: 8px;
    padding: 20px 15px;
  }
  .e_content_title {
    font-weight: 600;
    @extend %font14;
    line-height: 17px;
    color: $light_grey;
  }
  .e_content {
    font-weight: 400!important;
    @extend %font14;
    color: $dark_grey;
  }
  // .e_span {
  //   padding: 10px 24px;
  // }

  // p {
  //   @extend %font14;
  //   line-height: 24px;
  //   color: $light_grey;
  //   font-weight: 400;
  // }
  // .e_target {
  //   margin-right: 10px;
  // }

}
