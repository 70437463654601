@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:    Admin Template
Version:    1.0.0
Created:    08-11-2021
Use:
-------------------------------------------------------------------*/

/*--- 1. Import Fonts ----*/

$font-primary: 'Monsterrat';
$font-secondary: 'Sentient';
@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Montserrat/Montserrat-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Montserrat/Montserrat-SemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Montserrat/Montserrat-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Montserrat/Montserrat-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Montserrat/Montserrat-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: $font-secondary;
  src: url(../assets/fonts/Sentient/Sentient-Bold.otf);
  font-weight: 700;
}

/* --- 2. Colors --- */
$primary_color: #1329E6;
$secondary_color: #C5C5C5;
$dark_green: #2E6A0F;
$body_bg:#F6F9FF;
$hover_color:  #0B1FCA;
$white: #ffffff;
$black: #000000;
$dark_grey: #010101;
$secondary_btn_hover: #EDF3FF;
$secondary_hover_border: #0B1FCA;
$light_grey: #6D6D6D;
$card_bg: #F2F6F6;
$toast_success_border: #00d1b1;
$toast_success_bg: #d4ece8f5;
$toast_success_color: #089487;
$toast_fail_bg: #F9E5E5;
$input_boder: #d2d2d2;
$input_placeholder: #9EA3AA;
$error: #FF523B;
$accordion_bg: #F0F0F0;
$delete: #EF4A3C;
$badge_fail: #DE2020;;
$footer_bg: #F6F9FF;
$green: #16A377;
$orange: #FFB61D;
$red : #F93059;
$off_white: #F3F3F3;
$light_green: rgba(22, 163, 119, 0.1);
$search_bg: #F3F3F3;
$notification_boder: #F0F0F0;
$secondary_grey: #4F4F4F;
$download_bg: #edf3ff;
$profile_bg:#EDF3FF;
$status_bg: #0FD99C;
$tooltip_color: #eef2f5;
$table_row: #F1F7F2;
$accordion_body_bg: #dbdbdb;
$toast_color: #f1f1f1;
$warning_color: #FFC300;
$transaction_bg: #FAFAFA;


/* --- 3. Font --- */
%font10 { font-size: 0.625rem; }
%font11 { font-size: 0.688rem; }
%font12 { font-size: 0.750rem; }
%font14 { font-size: 0.875rem; }
%font16 { font-size: 1.000rem; }
%font18 { font-size: 1.125rem; }
%font20 { font-size: 1.250rem; }
%font24 { font-size: 1.500rem; }
%font32 { font-size: 2rem; }
%font36 { font-size: 2.25rem; }
%font48 { font-size: 3rem; }


/* --- 2. Global --- */
%heading {
  color: $dark_grey;
  font-weight: 600!important;
  -webkit-font-smoothing: antialiased;
}
%h4 {
  color: $dark_grey;
  @extend %font24;
  font-weight: 700;
  line-height: 120%;
  -webkit-font-smoothing: antialiased;
}
%h6{
  color: $dark_grey;
  @extend %font14;
  line-height: 17px;
  font-weight: 600!important;
}
.modal-dialog {
  max-width: 696px!important;
  // height: 556px!important;
}
.modal-content {
  padding: 15px!important;
  border-radius: 8px!important;
}
.modal-header {
  border-bottom: none!important;
}
.btn-close {
  opacity: 10!important;
}
button:focus:not(:focus-visible) {
  outline: none!important;
}
.btn-close:focus {
  box-shadow: none!important;
}
.btn-close:hover{
  transform: scale(1.1);
}


/* ---3. Mixin styles ---*/

// primary button
@mixin hover_animation(){
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  transition: all .35s;
  z-index: 1;
  opacity: 0;
}

/* ---4. variable ---*/
