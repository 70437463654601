/*
 *   File : phoneInput.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Phone Input style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.PhoneInput{
  background: $white;
  border: 1px solid $input_boder;
  background-color: transparent;
  outline: none;
  width: 100%;
  font-weight: 400;
  color: $black;
  padding: 10px 15px;

  &::focus{
    border: 1px solid $primary_color;
  }
}
.PhoneInput--focus::focus{
  border: 1px solid $primary_color;
}
.PhoneInput--focus{
  border: 1px solid $primary_color;
  outline: none!important;
  &::placeholder{
  font-weight: 500;
  @extend %font14;
  line-height: 18px;
  color: $black;
  opacity: 0.47;
  }
}
.PhoneInput{
  input{
    border: none!important;
    outline: none!important;
    &::placeholder{
      font-weight: 400;
      @extend %font14;
      line-height: 18px;
      color: $input_placeholder;
      letter-spacing: 0.25px;
    }
    &:focus{
      border: none!important;
      outline: none!important;
    }

  }
}
.e_input_error{
  border: 1px solid $error;

}
.e_error_msg{
  @extend %font12;
  color: $error !important;
}
