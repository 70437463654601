/*
 *   File : loader.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : loader
 *   Integrations :
 *   Version : v1.0
 *   Created : 29-10-2021
 */

@import "./../config.scss";

.e_filter_btn{
  background-color: $white;
  border: 1px solid $off_white;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 4px 0px;
  text-align: center;
  &:hover {
    cursor: pointer;
    border: 1px solid $primary_color;
  }
  img{
    width: 18px;
    height: 18px;

  }

}