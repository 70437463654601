/*
 *   File : confirmation.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : FAQ Accordion style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";
.e_confirmation {
  h6 {
    @extend %h6;
  }
  p {
    @extend %font14;
  }
  .e_search_funds_txt {
    font-weight: 600;
    @extend %font14;
    line-height: 130%;
    color: $light_grey;
    padding-top: 8px;
  }
}
