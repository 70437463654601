/*
 *   File : primaryLink.Text.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Primary Link Text Button Style
 *   Integrations : null
 *   Version : v1.0
 */

@import "./../config.scss";

.e_text_btn{
  color: $primary_color;
  font-weight: 700;
  @extend %font14;
  background-color: transparent;
  border: none;
  outline: none;
  line-height: 17px;
  position: relative;
  &:after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    transition: all .35s;
    z-index: 1;
    color: $black!important;
  }
  &:hover:after{
    width: 100%;
  }
  img {
    padding-right: 6px;
    position: relative;
    top: -2px;
  }
}