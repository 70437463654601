/*
 *   File : summary.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : summary.module.scss
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

/* style for card summary  in dashboard */
.e_summary_card{
  background-color: $white;
  box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.04);
  border-radius: 12px!important;
  padding: 24px!important;
  transition: box-shadow .3s;
  height: 134px;
  z-index: 0;
  border: none!important;
  outline: none;
  &:hover{
    // cursor: pointer;
    box-shadow: 0 0 11px rgba(33,33,33,.2);
  }
  .e_summary_card_icon{
    height: 32px;
    width: 32px;
    object-fit: cover;
  }
  h5{
    @extend %font14;
    line-height: 18px;
    -webkit-font-smoothing: antialiased;
    z-index: 1;
    padding-bottom: 20px;
    font-weight: 600;
  }
  span {
    font-weight: 600;
    font-size: 28px;
    line-height: 44px;
    color: $dark_grey;
    .e_text {
      @extend %font18;
    }
  }
}
.e_blue_box{
  background-color: $primary_color!important;
  box-shadow: 8px 16px 56px rgb(0 0 0 / 4%);
  border-radius: 12px !important;
  padding: 24px !important;
  transition: box-shadow 0.3s;
  height: 134px;
  z-index: 0;
  border: none !important;
  outline: none;
  &:hover{
    cursor: pointer;
    box-shadow: 0 0 11px rgba(33,33,33,.2);
  }
  .e_summary_card_icon{
    height: 32px;
    width: 60px;
  }
  h5{
    @extend %font14;
    line-height: 18px;
    -webkit-font-smoothing: antialiased;
    z-index: 1;
    padding-bottom: 20px;
    font-weight: 600;
    color: $white;
  }
  span {
    font-weight: 600;
    @extend %font36;
    line-height: 44px;
    color:$white;
    .e_text {
      @extend %font18;
    }
  }
}
.e_summary_card_icon {
  z-index: 0;
  position: relative;
  top: 5px;
  width: 32px;
  height: 32px;
}

