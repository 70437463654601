@charset "utf-8";
/* ==============================
   Small desktop
   ============================== */
@media only screen and (max-width: 1600px) {

}
@media screen and (max-width: 1400px) {
   .e-dashboard-card-text{
      font-size: 28px!important;
   }
  
}

@media only screen and (max-width: 1366px) {

}

@media only screen and (max-width: 1200px) {


}


@media only screen and (max-width: 1199px) {
   /*Header*/
  .e-header-desktop{
    display: none;
  }
  .e-header-mobile{
    display: block;
  }
  .e-dashboard-card-text{
      font-size: 24px!important;
   }

}

/* ==============================
   Tablet landskape
   ============================== */
@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 992px) {


}

/* ==============================
   Tablet Portrait
   ============================== */
@media only screen and (max-width: 768px) {


}

/* ==============================
   Phone
   ============================== */
@media only screen and (max-width: 575px) {



}

@media only screen and (max-width: 380px) {


}


/* ==============================
   Small Phone
   ============================== */
@media only screen and (max-width: 319px) {


}