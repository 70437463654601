/*
 *   File : loader.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : loader
 *   Integrations :
 *   Version : v1.0
 *   Created : 10-11-2021
 */

@import "./../config.scss";

.e_loader{
  width: 100%;
  height: 100vh!important;
  background-color: transparent;
  img{
    width: 68px;
    height: 68px;
    position: absolute;
    top: 30%;
    left: 50%;
  }

}
