/*-- Import supporting css*/
@import "./config.scss";
@import "./components/selectBox.module.scss";
@import "./components/dataTable.module.scss";
@import "./components/pie.module.scss";
@import "./components/bar.module.scss";
@import "./components/area.module.scss";
@import "./components/stacked.module.scss";
@import "./components/dateRangepicker.module.scss";
@import "./components/risk.module.scss";
@import "./components/fundClass.module.scss";
@import "./components/radio.module.scss";

/*---------- Body ---------- */
body {
  font-family: $font-primary;
  background-color: $body_bg;
  -webkit-font-smoothing: antialiased;

  &::-webkit-scrollbar {
    display: none !important;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
}

label {
  @extend %font12;
  font-weight: 400;
}

a {
  text-decoration: none;
}

/*------- App layout --------*/
.e-content-wrap {
  min-height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.e-sidebar-wrap {
  transition: ease-in-out 0.5s;
  position: relative;
  height: 100vh;
  width: 307px;
}

.e-sidebar-collapsed {
  transition: ease-in-out 0.5s;
  width: 50px;
}

.e-content-body {
  margin-top: 11.5%;
}

/*----auth--------*/
.e-auth-wrapper {
  background-color: $white;

  .e-form-wrap {
    background: $white;
    box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 25px;

    h4 {
      @extend %h4;
      line-height: 33px;
      font-family: $font-primary;
      color: $black;
    }

    p {
      font-weight: 400;
      @extend %font14;

      span {
        font-weight: 600;
      }
    }
  }

  .e-disabled {
    opacity: 0.5;
    pointer-events: none;
    font-weight: 700;
    @extend %font14;
  }
}

.e-auth-footer {
  margin-top: 100px;
}

.e-disabled {
  opacity: 0.5;
  pointer-events: none;
  font-weight: 700;
  @extend %font14;
}

/*------ Dashboard ------ */
.e-card-title {
  @extend %h6;
  font-weight: 600 !important;
  color: $black  !important;
  @extend %font14;
}

.e-dashboard-card {
  border-radius: 8px;
  box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.04);
  background: $white;
  padding: 12px;
  height: 100%;

  .e-dashboard-card-alloc {
    border-radius: 8px;
    box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.04);
    background: $white;
    padding: 12px;

    span {
      @extend %font12;
      color: $light_grey;
      font-weight: 600;
    }
  }

  .e-btn-active-wrapper {
    width: 45px;
    height: 39px;
    background: $primary_color;
    border-radius: 4px;
    padding: 8px 10px;
    margin-right: 12px;
    text-align: center;
    cursor: pointer;

    .e-btn-active {
      font-weight: 500;
      @extend %font14;
      line-height: 24px;
      color: $white;
    }
  }

  .e-btn-wrapper {
    width: 45px;
    height: 39px;
    background: $white;
    border-radius: 4px;
    padding: 8px 10px;
    margin-right: 12px;
    text-align: center;
    cursor: pointer;
    filter: drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.16));

    &:hover {
      transform: scale(1.02);
    }

    .e-btn-active {
      font-weight: 500;
      @extend %font14;
      line-height: 24px;
      color: $light_grey;
    }
  }

  .css-26l3qy-menu,
  .css-4ljt47-MenuList {
    z-index: 2;
    @extend %font12;
  }

  .e-radial {
    .apexcharts-canvas {
      left: -129px;
      top: -20px;
    }
  }

  .e-s-btn {

    // width: 86px;
    .secondary_e_s_btn__16DLu {
      padding: 10px;
    }
  }

  .e-piechart-label {
    top: 20px;
    right: 15%;

    h2 {
      color: $dark_grey;
      font-weight: 600;
      @extend %font24;
      line-height: 29px;
      margin-block-end: 6px;

      span {
        @extend %font18;
      }
    }

    p {
      color: $dark_grey;
      font-weight: 500;
      @extend %font14;
      line-height: 17px;
    }
  }
}

.e-bar-scroll {
  position: relative;
  overflow-x: scroll;
  width: 100%;
}

.e-bar-scroll::-webkit-scrollbar-button {
  display: none;
}

.e-bar-scroll::-webkit-scrollbar {
  width: 16px;
  height: 12px;
  padding: 20px;
  border-radius: 16px;
}

.e-bar-scroll::-webkit-scrollbar-track {
  background-color: #efefef;
  border-radius: 16px;
  margin: 20px;
  border-bottom: 3px solid white;
}

.e-bar-scroll::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 16px;
  border: 0px solid #fff;
  border-bottom: 3px solid white;
}

.e-round-chart-container {
  height: 420px;
}

.e-dashboard-row-2 {
  height: 515px;
}

/*------ App notification ------*/
.e-notification .tab_e_active_tab__3jjiq {
  background-color: transparent !important;
  color: $black;
  border-bottom: 3px solid $primary_color;
  border-radius: 0px;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 6px 0px;
  font-weight: 600;
  @extend %font14;
  line-height: 17px;
}

.e-notification-form-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 26%;
    height: 2.5px;
    background-color: $primary_color;
    bottom: -5px;
    left: 0;
    border-radius: 8px;
  }
}

.e-notification .e-disabled {
  opacity: 0.5;
  pointer-events: none;
  font-weight: 700;
  @extend %font14;
}

.e-notification .tab_e_tab__3yoyr {
  color: $black;
  border: none;
  padding: 6px 0px;
  border-radius: 0px;
  font-weight: 600;
  @extend %font14;
  line-height: 17px;
  border-bottom: 3px solid transparent;

  &:hover {
    background-color: transparent;
  }
}

.e-notification-form-element {
  &::placeholder {
    opacity: 1;
    color: $dark_grey;
  }
}

.e-notification-form-btn {
  @extend %font14;
}

.e-offer-body {
  height: 120px;
}

.e-notification-history {
  background-color: $accordion_bg;
  border-radius: 8px;

  .e-notification-history-time {
    color: $light_grey;
    line-height: 15px;
    @extend %font12;
    font-weight: 400;
  }

  .e-notification-history-content {
    color: $light_grey;
    line-height: 20px;
    @extend %font14;
    font-weight: 400;
  }

  .e-title {
    color: $dark_grey;
    font-weight: 600;
    line-height: 20px;
    @extend %font14;
  }
}

.e-notification-sec {
  background: #edf3ff;
  border-radius: 8px;
  cursor: pointer;

  p {
    @extend %font14;
    line-height: 20px;
    color: $light_grey;
    font-weight: 400;
  }
}

.e-notification-sub-sec {
  background-color: $white;
  cursor: pointer;

  h4 {
    @extend %font14;
    line-height: 20px;
    color: $dark_grey  !important;
    font-weight: 400 !important;
  }

  p {
    @extend %font14;
    line-height: 20px;
    color: $light_grey;
    font-weight: 400;
  }
}

/*----- Manage Content ------*/
.e-add-faq-btn {
  @extend %font14;
  width: 90%;
  padding: 14px;
}

.e-faq-card {
  min-height: 600px;

  h6 {
    color: $black  !important;
    font-weight: 700 !important;
  }
}

.e-cancel {
  width: 98px;
  height: 40px;
  padding: 0 !important;
  margin-right: 14px;
}

.e-modal-submit {
  font-weight: 600 !important;
  padding: 0 !important;
  @extend %font14;
  width: 129px;
  height: 40px;
}

.e-filter-btn {
  width: 36px;
  height: 36px;
}

.e-breadcrumb-link {
  font-weight: 300;
  @extend %font14;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.87);
  position: relative;
  top: -100%;

  a {
    text-decoration: none;
    color: $primary_color;
    padding-right: 4px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.e-nodata {
  width: 256px;
  height: 202px;
  margin: auto;
}

/*----- Chat Box -------*/
.e-chat-box {
  box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  height: 884px;

  .e-placeholder {
    width: 48px;
    height: 48px;
    color: $white;
    font-weight: 600;
    @extend %font14;
    line-height: 130%;
  }

  .e-user-name {
    color: $light_grey;
    @extend %font12;
  }

  .e-status-badge-open {
    color: $light_grey;
    @extend %font10;
    border: 1px solid $light_grey;
    box-sizing: border-box;
    border-radius: 100px;
    text-transform: capitalize;
    width: max-content;
    padding: 1px 8px;
  }

  .e-status-badge-pending {
    @extend .e-status-badge-open;
    color: $orange;
    border: 1px solid $orange;
  }

  .e-status-badge-closed {
    @extend .e-status-badge-open;
    color: $primary_color;
    border: 1px solid $primary_color;
  }

  h6 {
    @extend %h6;
    font-weight: 600;
  }

  .e-chat-wrap {
    transition: ease 0.3s;

    &:hover {
      background-color: rgba(185, 245, 163, 0.3);
      cursor: pointer;
    }
  }
}

/*-----Chat container ------*/
.e-chat-container {
  border-radius: 8px;
  height: 884px;

  .e-chat-header {
    box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.04);

    .e-user-placeholder {
      width: 48px;
      height: 48px;
      font-weight: 600;
      @extend %font14;
      line-height: 130%;
      color: $white;
    }

    h6 {
      color: $dark_grey;
      line-height: 130%;
      font-weight: 600;
      @extend %font18;
    }

    p {
      color: $light_grey;
      font-weight: normal;
      @extend %font14;
      line-height: 17px;
    }

    .e-ticket-label {
      @extend %h6;
    }

    .e-ticket {
      color: $light_grey;
      @extend %font14;
      line-height: 17px;
    }
  }

  .e-chat-placeholder {
    width: 32px;
    height: 32px;
    color: $white;
    @extend %font12;
    font-weight: 600;
    line-height: 130%;
  }

  .e-admin-icon {
    width: 32px;
    height: 32px;
    object-fit: cover;
  }

  .e-chat-content {
    color: $dark_grey;
    @extend %font14;
    line-height: 130%;
  }

  .e-date {
    color: $light_grey;
    line-height: 15px;
    @extend %font12;
  }

  .e-chat-row {
    border-bottom: 1px solid $off_white;
  }

  .e-closed-badge {
    background-color: $light_green;
    border-radius: 100px;
    color: $green;
    line-height: 15px;
    position: absolute;
    left: 37%;
    @extend %font12;

    img {
      width: 13.33px;
      height: 13.33px;
      object-fit: cover;
    }
  }

  .e-message-input {

    // margin-top: 310px;
    .e-input-icon {
      position: absolute;
      width: 30px;
      height: 30px;
      object-fit: cover;
      z-index: 2;
      top: 10px;
      left: 25px;
    }

    .e-sent-btn {
      position: absolute;
      right: 5px;
      z-index: 2;
      width: 50px;
      height: 100%;

      &:hover {
        cursor: pointer;
      }
    }

    .e-chat-input {
      background-color: $off_white;
      border: none;
      position: relative;
      padding: 14px 50px 14px 50px;

      &:placeholder {
        color: $light_grey;
      }
    }
  }

  .e-chatbox-wrper {
    height: 685px;
  }
}

.e-chat-active {
  background-color: red !important;
}

/*------Users-----*/
.e-filter-wraper {
  background: #e7eded;
  padding: 20px 0px 20px 20px;

  label {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #2b4240;
  }
}
.e-user-download{ 
  position: absolute;
  top: -80Px; 
  right: 0;
  width: 232px;
}

.e-filter-btn.e-active {
  border: 1px solid $primary_color;
}

.e-profile-holdings {
  h4 {
    font-family: $font-primary;
    font-weight: 700;
    @extend %font14;
    color: $dark_grey;
  }

  p {
    font-family: $font-primary;
    font-weight: 600;
    @extend %font18;
    color: $black;
  }
}

.e-profile-graph {
  height: 100%;

  h4 {
    @extend %font14;
    color: $black;
    font-weight: 700;
  }

  p {
    font-family: $font-primary;
    font-weight: 500;
    @extend %font14;
    color: $light_grey;
  }
}

.e-graph-labels {
  max-height: 170px;
  overflow-y: scroll;
}

.e-graph-labels::-webkit-scrollbar {
  height: 0px !important;
  width: 8px;
}

.e-graph-labels::-webkit-scrollbar-thumb {
  background: #edf3ff;
}

.e-order-detail {
  max-height: 420px;
  overflow-y: scroll;
  overflow-x: hidden;

  .e-order-row {
    background: rgba(13, 13, 13, 0.02);
  }
}

.e-order-detail::-webkit-scrollbar {
  height: 0px !important;
  width: 4px;
}

.e-order-detail::-webkit-scrollbar-thumb {
  background: #edf3ff;
}

.e-icon-tooltip {
  right: 0 !important;
  max-width: 300px;
  top: 84px;
  z-index: 3;
}

.e-holding-table {
  .e-deny {
    padding: 12px 24px !important;
  }

  h4 {
    @extend %font14;
    color: $black;
    font-weight: 700;
  }

  .e-profile-active-btn {
    background: $primary_color;
    border-radius: 4px;
    color: $notification_boder;
    @extend %font14;
    padding: 4px 8px;
    width: 100%;
    margin-left: 10px;
    cursor: pointer;
  }

  .e-profile-btn {
    background: $notification_boder;
    border-radius: 4px;
    color: $black;
    @extend %font14;
    padding: 4px 8px;
    width: 100%;
    margin-left: 10px;
    cursor: pointer;
  }

  .e-holding-table-heading {
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  }

  .e-holding-table-data {
    background-color: $download_bg;
    padding: 30px;

    .e-table-data {
      font-family: $font-primary;
      font-weight: 500;
      @extend %font14;
      color: $dark_grey;
      line-height: 24px;
    }

    .e-table-data-percent {
      font-family: $font-primary;
      font-weight: 500;
      @extend %font14;
      color: $dark_grey;
      position: relative;
      top: -2px;
      left: 0px;
    }

    .e-current-data-desc {
      font-family: $font-primary;
      font-style: normal;
      font-weight: 500;
      @extend %font14;
      color: #0fd99c;
    }
  }
}

.e-user-request {
  .e-user-request-sec {
    background-color: $download_bg;
    padding: 30px;
  }

  h4 {
    font-family: $font-primary;
    font-weight: 700;
    @extend %font14;
    color: $dark_grey;
  }

  p {
    color: $dark_grey  !important;
    @extend %font14;

    .e-bold {
      font-weight: 600;
    }
  }
}

.progress {
  background-color: transparent !important;
  justify-content: start;

  .progress-bar {
    border-radius: 34px;
    margin-top: 5px;
  }

  .progress-bar-international {
    background-color: #6439ec;
    border-radius: 34px;
  }
}

.e-current-title {
  font-family: $font-primary;
  font-weight: 600;
  @extend %font14;
  color: $light_grey;
}

.e-current-value {
  font-family: $font-primary;
  font-weight: 600;
  @extend %font18;
  color: $primary_color  !important;
}

/*-- Analytics --*/
.e-analytics-card {
  .tab_e_active_tab__2nkVn {
    background-color: transparent !important;
    padding: 0px;
    border: none !important;
    color: $white  !important;
    opacity: 1 !important;
    span {
      // padding: 12px 12px 4px 12px;
      color: $white !important;
      &::after {
        content: "";
        display: block;
        width: 100%;
        position: relative;
        bottom: 0px;
        top: 9px;
        height: 2px;
        background: $primary_color;
        transition: width 0.3s;
        z-index: 1;
      }
    }
   
  }

  .tab_e_tab__-VrS6 {
    padding: 0px;
    background-color: transparent !important;
    border: none !important;
    color: $white  !important;
   
    span {
      color: $white !important;
      // padding: 12px 12px 0px 12px;
      &:hover {
        opacity: 1;
      }
      &:before {
        content: "";
        position: absolute;
        width: 0px;
        height: 3px;
        bottom: -9px;
        left: 0;
        visibility: visible;
        // color: $primary_color;
        background-color: $primary_color;
        transition: ease-in-out 0.5s;
      }
      &:hover {
        &:before {
          width: 100%;
          height: 3px;
        }
      }
    }
  }
}



.e-drop {
  color: #de2020;
}

.e-analytics {
  .css-1s2u09g-control {
    min-height: 20px !important;
    border-radius: 4px !important;
  }

  .css-1pahdxg-control {
    min-height: 20px !important;
    border-radius: 4px !important;
  }
}

/*---Quiz---*/
.e-quiz-card {
  .e-quiz-scroll {
    overflow-x: scroll;
  }

  .e-quiz-scroll::-webkit-scrollbar {
    display: none;
  }

  .tab_e_tab__3yoyr,
  .tab_e_tab__2sfKQ {
    background-color: $accordion_bg  !important;
    border-radius: 8px !important;
    color: $dark_grey;
    padding: 12px 15px !important;
    min-width: 218px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  .tab_e_active_tab__3jjiq {
    border-radius: 8px !important;
    padding: 12px 15px !important;
    min-width: 218px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.e-quiz-tab {
  width: 225px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-quiz-icon {
  position: absolute;
  top: 32%;
  right: 0px;
  background-image: url(../assets/images/dataFormating/add.svg);
  background-repeat: no-repeat;
  padding: 13px;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.e-add-sec {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.48);
  border-radius: 8px;
  width: 100%;
  padding: 2px 10px 7px;
  margin-bottom: 20px;

  h6 {
    color: $primary_color  !important;
    @extend %font14;
    line-height: 24px;
    font-weight: 700 !important;
    padding: 12px 10px 0px;
    width: 100%;

    span {
      cursor: pointer;
    }

    &:hover {
      translate: scale(1.5);
    }
  }
}

.e-add-quiz {
  font-weight: 700;
  @extend %font14;
  line-height: 24px;
  color: $primary_color;
  padding: 12px 30px;
  border: 1px solid $accordion_bg;
  border-radius: 8px;
  background-color: $accordion_bg;
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }
}

/*------ edit score request css---*/
.e-request-card {
  .e-reject-link {
    color: $badge_fail;
  }

  .e-request-status {
    font-weight: 600;
    line-height: 17px;
    @extend %font14;
    color: $primary_color;
    text-align: center;
  }

  .e-request-rejected {
    @extend .e-request-status;
    color: red;
  }

  .e-request-status-date {
    line-height: 15px;
    @extend %font12;
    color: $light_grey;
    font-weight: 400;
    text-align: center;
  }
}

/*-- report css--*/
.e-report {
  min-height: 140vh;

  p {
    font-weight: 400;
    @extend %font14;
    line-height: 20px;
    color: $dark_grey;
    padding-right: 4px;
  }

  h6 {
    font-weight: 600;
    @extend %font14;
    line-height: 20px;
    color: $dark_grey;
  }

  .e-report-save-btn {
    padding: 8px 16px;

    span {
      line-height: 24px;
    }
  }

  .e-send-report {
    padding: 8px 16px;

    span {
      line-height: 24px;
    }
  }
}

/* manage global class css */
.e-global-class-card {
  background-color: $accordion_bg  !important;
  border-radius: 8px !important;
  color: #010101;
  padding: 12px 24px !important;
  min-width: 218px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // background-image: url("../assets/images/cta/delete-topic.svg");
  // background-repeat: no-repeat;
  // background-position: 95%;
}

/* -- modal select and button --*/
.modal .css-1s2u09g-control,
.modal .css-1pahdxg-control {
  border-radius: 8px !important;
  height: 47px;

  &:hover {
    border-radius: 8px !important;
  }
}

.modal .e-disabled {
  opacity: 0.5;
  pointer-events: none;
  font-weight: 700;
  @extend %font14;
}

.e-edit-modal {
  width: 109px !important;
}

/*--- phone input css---*/
.PhoneInput {
  background: $white;
  border: 1px solid $input_boder;
  background-color: transparent;
  outline: none;
  width: 100%;
  font-weight: 400;
  color: $black;
  padding: 10px 15px;
  border-radius: 8px;

  &::focus {
    border: 1px solid $primary_color;
    border-radius: 8px;
  }
}

.PhoneInput--focus::focus {
  border: 1px solid $primary_color;
  border-radius: 8px;
}

.PhoneInput--focus {
  border: 1px solid $primary_color;
  outline: none !important;

  &::placeholder {
    font-weight: 500;
    @extend %font14;
    line-height: 18px;
    color: $black;
    opacity: 0.47;
  }
}

.PhoneInput {
  input {
    border: none !important;
    outline: none !important;

    &::placeholder {
      font-weight: 400;
      @extend %font14;
      line-height: 18px;
      color: $input_placeholder;
      letter-spacing: 0.25px;
    }

    &:focus {
      border: none !important;
      outline: none !important;
    }
  }
}

.ant-pagination-options-size-changer.ant-select {
  width: auto;
  display: none;
}

/*-------loader---*/
.e-btn-loader span {
  &:after {
    content: " .";
    font-family: "Epilogue";
    margin: 0;
    line-height: 100%;
    animation: dots 1s steps(1, end) infinite;
  }
}

@keyframes dots {

  0%,
  12.5% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    text-shadow: 0.5em 0;
  }

  75%,
  100% {
    text-shadow: 0.5em 0, 1em 0;
  }
}

.ck-editor__editable {
  height: 200px;
}
.ck-file-dialog-button {
  display: none!important;
}
.ck-dropdown {
  // display: none!important;
} 
 .ck-toolbar__items {
   &button :nth-of-type(9) {
      display :none!important;
   }
 }

 .e-header-tooltip {
  width: 46px;
  position: absolute;
  top: -71px;
  right: 235px;
  cursor: pointer;
 }
 .e-tooltip-user-list {
  top: -33px;
  right: 140px;
  width: 250px;
 }
 
  // display: none!important;


// .ck.ck-dropdown{
//   &[aria-haspopup="true"]{
//   display: none!important;
//   }
// }
// .ck.ck-dropdown{
//   &[aria-labelledby="ck-editor__aria-label_e74d295f4749ee2c9e4e0304f597225aa"]{
//     display: none!important;
//   }
// }